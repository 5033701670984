import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Col,
  DatePicker,
  Popconfirm,
  Row,
  Select,
  Table,
  Form,
  Button,
  Spin,
  Space,
  InputNumber,
  message,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import {
  DeleteTwoTone,
  EditOutlined,
  HomeOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { AppContext } from "../../../context/AppContextProvider";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import {
  getBranches,
  getProductStockByBranch,
  updateStockTransfers,
} from "../../../network/network";

const EditTransfer = () => {
  const { appConfig } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [branchesData, setBranchesData] = useState([]);
  const [adjustmentData, setAdjustmentData] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [branchId, setBranchId] = useState(0);
  let { transferId } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  const [transferForm] = Form.useForm();

  useEffect(() => {
    state
      ? setData(state)
      : navigate("/inventory/stock-transfer/" + transferId);
  }, [navigate, state, transferId]);

  useEffect(() => {
    branchId &&
      getProductStockByBranch(branchId).then((response) => {
        setProductsList(response.data.data.products);
      });
  }, [branchId]);

  useEffect(() => {
    getBranches().then((response) => {
      setBranchesData(response.data.data.branches);
    });
  }, []);

  useEffect(() => {
    if (data.id) {
      setBranchId(data.branch_from.id);
      for (let i = 0; i < data.products.length; i++) {
        setLoading(true);
        for (let j = 0; j < productsList.length; j++) {
          if (data.products[i].product.id === productsList[j].product.id) {
            data.products[i].quantity = productsList[j].quantity;
            data.products[i].qty = data.products[i].requested_quantity;
            setAdjustmentData(data.products);
          }
        }
        setLoading(false);
      }
      transferForm.resetFields();
      // Set Form Fields
      transferForm.setFieldsValue({
        id: data.id,
        branch_from_id: data.branch_from.id,
        branch_to_id: data.branch_to.id,
        date_time: moment(data.date_time),
      });
    }
  }, [data, transferForm, productsList]);

  const adjustmentReducer = (product, action, quantity) => {
    let updatedAdjustment = JSON.parse(JSON.stringify(adjustmentData));
    let productId = product.product.id;
    let indexFound = updatedAdjustment.findIndex(
      (product) => product.product.id === productId
    );
    if (action === "add") {
      if (indexFound === -1) {
        product.qty = 1;
        updatedAdjustment.push(product);
      } else {
        message.error(t("product_already_selected"));
      }
    } else if (action === "inc") {
      updatedAdjustment[indexFound].qty++;
    } else if (action === "dec") {
      updatedAdjustment[indexFound].qty--;
    } else if (action === "remove") {
      updatedAdjustment.splice(indexFound, 1);
    } else if (action === "directInput") {
      updatedAdjustment[indexFound].qty = quantity;
    }
    setAdjustmentData(updatedAdjustment);
  };

  const handleEditTransfer = () => {
    transferForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        let transactionObj = {
          id: values.id,
          branch_from_id: values.branch_from_id,
          branch_to_id: values.branch_to_id,
          date_time: moment(values.date_time).format("YYYY-MM-DD HH:mm:ss"),
          // note: values.note === undefined ? "" : values.note,
        };
        transactionObj.stocks = adjustmentData.map((item) => ({
          product_id: item.product.id,
          requested_quantity: item.qty,
        }));

        // Sending Request to API
        updateStockTransfers(transactionObj)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("transfer_updated"),
                t("transfer_updated_successfully")
              );
              navigate("/inventory/stock-transfer/" + transferId);
            } else {
              // Error
              let errors = response.response.data.data.errors;
              if (errors && Object.keys(errors).length) {
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              } else {
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  data.message
                );
              }
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.message
            );
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleProductSelect = (value) => {
    const product = productsList.find(
      (product) => product.product.id === value
    );
    adjustmentReducer(product, "add");
    transferForm.setFieldsValue({
      product: "",
    });
  };

  const productTableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("image"),
      width: "90px",
      render: (record) => {
        return (
          <>
            <img
              alt={record.product.name_en}
              src={
                record.product.image
                  ? record.product.image
                  : "/images/image-not-found.png"
              }
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          </>
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          <>
            {appConfig.direction === "ltr"
              ? record.product.name_en
              : record.product.name}
          </>
        );
      },
    },
    {
      title: t("available_stock"),
      align: "center",
      dataIndex: "quantity",
    },
    {
      title: t("col_quantity"),
      width: "150px",
      render: (record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                justifyContent: "space-between",
                backgroundColor: "#e8f0fa",
                borderRadius: "35rem",
                width: "150px",
              }}
            >
              <MinusCircleFilled
                style={{
                  color: record.qty > 1 ? "#6301f2" : "#d9d9d9",
                  fontSize: "32px",
                  cursor: record.qty > 1 ? "pointer" : "not-allowed",
                }}
                onClick={() =>
                  record.qty > 1 && adjustmentReducer(record, "dec")
                }
              />
              <InputNumber
                min={0}
                onChange={(quantity) =>
                  record.quantity <= quantity
                    ? adjustmentReducer(record, "directInput", record.quantity)
                    : adjustmentReducer(record, "directInput", quantity)
                }
                value={record.qty}
              />
              <PlusCircleFilled
                style={{
                  color: record.quantity > record.qty ? "#6301f2" : "#d9d9d9",
                  fontSize: "32px",
                  cursor:
                    record.quantity > record.qty ? "pointer" : "not-allowed",
                }}
                onClick={() =>
                  record.quantity > record.qty &&
                  adjustmentReducer(record, "inc")
                }
              />
            </div>
          </>
        );
      },
    },
    {
      title: t("action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Popconfirm
              title={t("warning_remove")}
              onConfirm={() => adjustmentReducer(record, "remove")}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/inventory/stock-transfer",
      breadcrumbName: t("menu_stock_transfer"),
      icon: <SettingOutlined />,
    },
    {
      path: "edit-transfer",
      breadcrumbName: t("menu_edit_transfer"),
      icon: <EditOutlined />,
    },
  ];

  return (
    <Spin spinning={loading}>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_edit_transfer")}
          onBack={() => window.history.back()}
          ghost={false}
          style={{ padding: "0" }}
        />
      </Card>
      <Card>
        {data.id && (
          <Form
            name="StockTranser-form"
            layout="vertical"
            onFinish={handleEditTransfer}
            style={{ width: "100%", margin: "0" }}
            form={transferForm}
          >
            <Form.Item name="id" hidden={true}>
              <input />
            </Form.Item>
            <Row gutter={[20, 20]}>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="branch_from_id"
                  label={t("col_branch_from")}
                  rules={[
                    {
                      required: true,
                      message: t("validate_branch_required"),
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled
                    showSearch
                    allowClear
                    onChange={(e) => {
                      setBranchId(e);
                      setAdjustmentData([]);
                    }}
                    options={branchesData
                      .filter((branch) => {
                        if (
                          branch.id !==
                          transferForm.getFieldValue("branch_to_id")
                        ) {
                          return branch;
                        } else return null;
                      })
                      .map((branch) => {
                        return {
                          label: branch.name,
                          value: branch.id,
                        };
                      })}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="branch_to_id"
                  label={t("col_branch_to") + " (" + t("your_branch") + ")"}
                  rules={[
                    {
                      required: true,
                      message: t("validate_branch_required"),
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    disabled
                    showSearch
                    allowClear
                    options={branchesData.map((branch) => {
                      return {
                        label: branch.name,
                        value: branch.id,
                      };
                    })}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="date_time"
                  label={t("col_date")}
                  rules={[
                    {
                      required: true,
                      message: t("validate_date_required"),
                    },
                  ]}
                  hasFeedback
                >
                  <DatePicker
                    disabled
                    disabledDate={(current) => {
                      return current && current < moment().add(-1, "day");
                    }}
                    format="YYYY/MM/DD"
                    allowClear
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              {branchId ? (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <Form.Item
                    name="product"
                    label={t("product_add")}
                    rules={[
                      adjustmentData.length < 1 && {
                        required: true,
                        message: t("validate_product_name_required"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      options={productsList
                        .filter((product) => {
                          if (product.quantity > 0) {
                            return product;
                          } else return null;
                        })
                        .map((product) => {
                          return {
                            label:
                              appConfig.direction === "ltr"
                                ? `${product.product.name_en} (${product.quantity})`
                                : `${product.product.name} (${product.quantity})`,
                            value: product.product.id,
                          };
                        })}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size="large"
                      onChange={handleProductSelect}
                    />
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
              {adjustmentData.length > 0 && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <Table
                    columns={productTableColumns}
                    dataSource={adjustmentData}
                    rowKey="id"
                    pagination={false}
                  />
                </Col>
              )}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <Form.Item style={{ textAlign: "center" }}>
                  <Space>
                    <Button
                      key="back"
                      onClick={() =>
                        navigate("/inventory/stock-transfer/" + transferId)
                      }
                    >
                      {t("cancel")}
                    </Button>
                    <Button type="primary" htmlType="submit">
                      {t("update")}
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </Spin>
  );
};

export default EditTransfer;
