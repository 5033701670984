import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row, Spin, Table } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { EyeOutlined, HomeOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AppContext } from "../../../context/AppContextProvider";
import { getStockAdjustments } from "../../../network/network";

const ViewStockAdjustment = () => {
  const { appConfig } = useContext(AppContext);
  const [stockAdjustment, setStockAdjustment] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { adjustmentId } = useParams();

  useEffect(() => {
    setLoading(true);
    getStockAdjustments()
      .then((response) => {
        setStockAdjustment(
          response.data.data.stock_adjustments.stock_adjustments.find(
            (adjustment) => adjustment.id === Number(adjustmentId)
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [adjustmentId]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "inventory/stock-adjustment",
      breadcrumbName: t("menu_stock_adjustment"),
      icon: <SettingOutlined />,
    },
    {
      path: "view_stock_adjustment",
      breadcrumbName: t("menu_view_stock_adjustment"),
      icon: <EyeOutlined />,
    },
  ];

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("image"),
      width: "90px",
      render: (record) => {
        return (
          <>
            <img
              alt={record.product.name_en}
              src={
                record.product.image
                  ? record.product.image
                  : "/images/image-not-found.png"
              }
              style={{
                width: "50px",
                height: "auto",
                borderRadius: "1rem",
                border: "1px solid #ebebeb",
              }}
            />
          </>
        );
      },
    },
    {
      title: t("col_product"),
      render: (record) => {
        return (
          <>
            {appConfig.direction === "ltr"
              ? record.product.name_en
              : record.product.name}
          </>
        );
      },
    },
    {
      title: t("col_quantity"),
      render: (record) => {
        return <>{record.quantity}</>;
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_view_stock_adjustment")}
          onBack={() => navigate("/inventory/stock-adjustment")}
          ghost={false}
          style={{ padding: "0" }}
        />
      </Card>
      <Spin spinning={loading}>
        <Card>
          {stockAdjustment.id && (
            <>
              <Row gutter={[20, 20]}>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_date")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {stockAdjustment.date_time
                      ? moment(stockAdjustment.date_time).format("YYYY-MM-DD")
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_created_by")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {stockAdjustment.created_by
                      ? stockAdjustment.created_by.name
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("reference_no")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {stockAdjustment.reference_no
                      ? stockAdjustment.reference_no
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("branch")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {stockAdjustment.branch ? stockAdjustment.branch.name : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_quantity")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {stockAdjustment.total_quantity ? (
                      <div
                        style={{
                          color:
                            Math.sign(stockAdjustment.total_quantity) === 1
                              ? "#46c35f"
                              : "#fc2c10",
                        }}
                      >
                        {stockAdjustment.total_quantity}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("note")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {stockAdjustment.note ? stockAdjustment.note : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <Table
                    columns={tableColumns}
                    dataSource={stockAdjustment.stocks}
                    rowKey="id"
                    pagination={false}
                  />
                </Col>
              </Row>
            </>
          )}
        </Card>
      </Spin>
    </>
  );
};

export default ViewStockAdjustment;
