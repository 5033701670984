import "./SalesInvoices.css";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { AuditOutlined, HomeOutlined } from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import {
  SALES_INVOICE_STATUS_DRAFT,
  SALES_INVOICE_STATUS_OPEN,
  SALES_INVOICE_STATUS_COMPLETED,
} from "../../../configs/constants";
import { getBranches, getSaleInvoicesCount } from "../../../network/network";
import DraftInvoices from "./DraftInvoices";
import OpenInvoices from "./OpenInvoices";
import PaidInvoices from "./PaidInvoices";
import AddInvoice from "./AddInvoice";

const SalesInvoices = () => {
  const [reload, setReload] = useState(false);
  const [tab, setTab] = useState([]);
  const { t } = useTranslation();
  const [count, setCount] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches()
      .then((response) => {
        setBranchesData(response.data.data.branches);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  useEffect(() => {
    getSaleInvoicesCount().then((response) => {
      setCount(response.data.data);
    });
  }, [reload]);

  const onChangeTab = (key) => {
    setTab(key);
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "sales-invoice",
      breadcrumbName: t("menu_sales_invoice"),
      icon: <AuditOutlined />,
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_sales_invoice")}
          ghost={false}
          extra={<AddInvoice reloadCallback={() => setReload(!reload)} />}
        />
      </Card>
      <Spin spinning={isDataLoading}>
        <Row
          gutter={[20, 20]}
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          <Col span={8}>
            <Card
              className={
                "tab_title" +
                (tab === SALES_INVOICE_STATUS_DRAFT ? " active" : "")
              }
              onClick={() => onChangeTab(SALES_INVOICE_STATUS_DRAFT)}
            >
              <div>
                {t("draft_invoices")}
                <span className="tab_count">
                  {count.draft_sale_invoice_count}
                </span>
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              className={
                "tab_title" +
                (tab === SALES_INVOICE_STATUS_OPEN ? " active" : "")
              }
              onClick={() => onChangeTab(SALES_INVOICE_STATUS_OPEN)}
            >
              <div>
                {t("open_invoices")}
                <span className="tab_count">
                  {count.issue_sale_invoice_count}
                </span>
              </div>
            </Card>
          </Col>
          {/* <Col span={6}>
            <Card
              className={
                "tab_title" +
                (tab === SALES_INVOICE_STATUS_PARTIALLY ? " active" : "")
              }
              onClick={() => onChangeTab(SALES_INVOICE_STATUS_PARTIALLY)}
            >
              <div>
                {t("partially_paid_invoices")}
                <span className="tab_count">
                  {count.partialPaid_sale_invoice_count}
                </span>
              </div>
            </Card>
          </Col> */}
          <Col span={8}>
            <Card
              className={
                "tab_title" +
                (tab === SALES_INVOICE_STATUS_COMPLETED ? " active" : "")
              }
              onClick={() => onChangeTab(SALES_INVOICE_STATUS_COMPLETED)}
            >
              <div>
                {t("paid_invoices")}
                <span className="tab_count">
                  {count.paid_sale_invoice_count}
                </span>
              </div>
            </Card>
          </Col>
        </Row>
      </Spin>
      {tab === SALES_INVOICE_STATUS_DRAFT && (
        <DraftInvoices
          branchesData={branchesData}
          reloadCallback={() => setReload(!reload)}
        />
      )}
      {tab === SALES_INVOICE_STATUS_OPEN && (
        <OpenInvoices
          branchesData={branchesData}
          reloadCallback={() => setReload(!reload)}
        />
      )}
      {/* {tab === SALES_INVOICE_STATUS_PARTIALLY && (
        <PartiallyPaidInvoices
          branchesData={branchesData}
          reloadCallback={() => setReload(!reload)}
        />
      )} */}
      {tab === SALES_INVOICE_STATUS_COMPLETED && (
        <PaidInvoices
          branchesData={branchesData}
          reloadCallback={() => setReload(!reload)}
        />
      )}
    </>
  );
};

export default SalesInvoices;
