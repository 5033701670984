import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Select } from "antd";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import {
  ChangeTransactionWaiter,
  getEmployees,
} from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import { ADDON_WAITER_MANAGEMENT } from "../../../configs/constants";

const UpdateWaiter = ({ order, reloadCallback }) => {
  const { authUser } = useContext(AppContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [employees, setEmployees] = useState([]);
  const { t } = useTranslation();
  const [UpdateWaiterForm] = Form.useForm();

  useEffect(() => {
    setLoadingBTN(true);
    getEmployees()
      .then((response) => {
        setEmployees(
          response.data.data.owner.concat(response.data.data.employees)
        );
      })
      .finally(() => {
        setLoadingBTN(false);
      });
  }, []);

  const is_waiter_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_WAITER_MANAGEMENT
  );

  const showEditModel = () => {
    // Set Form Fields
    UpdateWaiterForm.setFieldsValue({
      id: order.id,
      waiter_id: order.waiter?.id,
    });
    setModalVisible(true);
  };

  const handleUpdateWaiter = () => {
    UpdateWaiterForm.validateFields()
      .then((values) => {
        // Set Loading True
        setLoadingBTN(true);
        // Sending Request to API
        ChangeTransactionWaiter(values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                is_waiter_management_active
                  ? t("waiter_updated")
                  : t("job_person_updated")
              );
              reloadCallback();
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                data.message
              );
            }
            // Hide Modal
            setModalVisible(false);
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoadingBTN(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      {employees.filter((employee) => {
        if (employee.is_waiter && employee.branch_id === authUser.branch_id) {
          return employee;
        } else return null;
      }).length > 0 && (
        <Button type="primary" onClick={showEditModel}>
          {order.waiter
            ? is_waiter_management_active
              ? t("update_waiter")
              : t("update_job_person")
            : is_waiter_management_active
            ? t("select_waiter")
            : t("select_job_person")}
        </Button>
      )}

      <Modal
        title={
          order.waiter
            ? is_waiter_management_active
              ? t("update_waiter")
              : t("update_job_person")
            : is_waiter_management_active
            ? t("select_waiter")
            : t("select_job_person")
        }
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onOk={handleUpdateWaiter}
        footer={[
          <Button
            key="back"
            loading={loadingBTN}
            onClick={() => setModalVisible(false)}
          >
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingBTN}
            onClick={handleUpdateWaiter}
          >
            {order.waiter ? t("update") : t("add")}
          </Button>,
        ]}
      >
        <Form
          name="add-waiter-form"
          layout="vertical"
          onFinish={handleUpdateWaiter}
          style={{ width: "100%" }}
          form={UpdateWaiterForm}
        >
          <Form.Item
            name="waiter_id"
            label={
              is_waiter_management_active
                ? t("select_waiter")
                : t("select_job_person")
            }
            rules={[
              {
                required: true,
                message: is_waiter_management_active
                  ? t("validate_waiter_required")
                  : t("validate_job_person_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              allowClear
              options={employees
                .filter((employee) => {
                  if (employee.is_waiter) {
                    return employee;
                  } else return null;
                })
                .map((employee) => {
                  return {
                    label: employee.name,
                    value: employee.id,
                  };
                })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateWaiter;
