import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Spin,
  Tag,
  Form,
  Modal,
  Select,
  Input,
  InputNumber,
  Table,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import moment from "moment";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { AuditOutlined, EyeOutlined, HomeOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  getSingleSaleInvoice,
  ChangeSaleInvoiceStatus,
  registerSaleInvoicePayment,
  getInvoicePaymentsDetails,
  getTransactionQRCode,
} from "../../../network/network";
import {
  SALES_INVOICE_STATUS_COMPLETED,
  SALES_INVOICE_STATUS_DRAFT,
  SALES_INVOICE_STATUS_OPEN,
  SALES_INVOICE_STATUS_PARTIALLY,
  TRANSACTION_TYPE_CASH,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_MULTI,
  USER_TYPE_BUSINESS_OWNER,
} from "../../../configs/constants";
import { AppContext } from "../../../context/AppContextProvider";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import ReactToPrint from "react-to-print";

const ViewSalesInvoice = () => {
  const { authUser, appConfig } = useContext(AppContext);
  const [data, setData] = useState(false);
  const [paymentData, setPaymentData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [reload, setReload] = useState(false);
  const [transactionQRCode, setTransactionQRCode] = useState("");
  let { salesInvoiceId } = useParams();
  const { t } = useTranslation();
  const [salesInvoiceForm] = Form.useForm();
  const transactionSlipRef = useRef();

  useEffect(() => {
    setLoading(true);
    getSingleSaleInvoice(salesInvoiceId)
      .then((response) => {
        setData(response.data.data.saleInvoice);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [salesInvoiceId, reload]);

  useEffect(() => {
    setLoading(true);
    getInvoicePaymentsDetails(salesInvoiceId)
      .then((response) => {
        setPaymentData(response.data.data.saleInvoicePayments);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [salesInvoiceId, reload]);

  useEffect(() => {
    data.id &&
      getTransactionQRCode(data.id).then((response) => {
        setTransactionQRCode(response.data.data.qr_code);
      });
  }, [data.id]);

  let paymentMethod =
    (data.type === TRANSACTION_TYPE_CASH && "Cash") ||
    (data.type === TRANSACTION_TYPE_CREDIT && "Card") ||
    (data.type === TRANSACTION_TYPE_MULTI && "Multi");

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "sales-invoice",
      breadcrumbName: t("menu_sales_invoice"),
      icon: <AuditOutlined />,
    },
    {
      path: "view-sales-invoice",
      breadcrumbName: t("menu_view_sales_invoice"),
      icon: <EyeOutlined />,
    },
  ];

  const updateStatus = (id) => {
    const formData = new FormData();
    if (id) {
      formData.append("id", id);
    }
    ChangeSaleInvoiceStatus(formData)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          // Success
          NotificationWithIcon(
            "success",
            t("entity_updated"),
            t("entity_updated_successfully")
          );
          // navigate("/sales-invoice");
          setReload(!reload);
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      });
  };

  const updatePayment = () => {
    salesInvoiceForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoadingBTN(true);
        // Sending Request to API
        registerSaleInvoicePayment(values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("entity_updated"),
                t("entity_updated_successfully")
              );
              setReload(!reload);
              setModalVisible(false);
              salesInvoiceForm.resetFields();
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                data.message
              );
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoadingBTN(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const showTableModal = () => {
    // Show Modal
    setModalVisible(true);
    // Explicitly reset Form Fields before setting them for edit
    // This ensures the form is in a clean state before being repopulated
    salesInvoiceForm.resetFields();
    // Set Form Fields
    salesInvoiceForm.setFieldsValue({
      payment: data.balance,
      balance: data.balance,
      transaction_id: data.id,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    salesInvoiceForm.resetFields();
    setModalVisible(false);
  };

  // TableColumns Columns
  const TableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("col_created_at"),
      dataIndex: "created_at",
      render: (created_at) => {
        return <p>{moment(created_at).format("MMM. D, YYYY hh:mm a")}</p>;
      },
    },
    {
      title: t("col_created_by"),
      render: (record) => {
        return <>{record.created_by.name ? record.created_by.name : "-"}</>;
      },
    },
    {
      title: t("payment_types"),
      render: (record) => {
        return <>{record.payment_method ? record.payment_method : "-"}</>;
      },
    },
    {
      title: t("col_amount"),
      render: (record) => {
        return (
          <>
            <p>
              <CurrencyFormatter value={record.payment} />
            </p>
          </>
        );
      },
    },
    {
      title: t("col_balance"),
      render: (record) => {
        return (
          <>
            <p>
              <CurrencyFormatter value={record.balance} />
            </p>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_view_sales_invoice")}
          onBack={() => window.history.back()}
          ghost={false}
          style={{ padding: "0" }}
          extra={[
            (authUser.can_add_pay_sales_invoice ||
              authUser.type === USER_TYPE_BUSINESS_OWNER) &&
              data.sale_invoice_status === SALES_INVOICE_STATUS_DRAFT && (
                <Button
                  key="updateStatus"
                  type="primary"
                  onClick={() => updateStatus(data.id)}
                >
                  {t("issue_invoice")}
                </Button>
              ),
            (authUser.can_add_pay_sales_invoice ||
              authUser.type === USER_TYPE_BUSINESS_OWNER) &&
              (data.sale_invoice_status === SALES_INVOICE_STATUS_OPEN ||
                data.sale_invoice_status ===
                  SALES_INVOICE_STATUS_PARTIALLY) && (
                <Button
                  key="showTableModal"
                  type="primary"
                  onClick={showTableModal}
                >
                  + {t("register_payment")}
                </Button>
              ),
            data.sale_invoice_status === SALES_INVOICE_STATUS_COMPLETED && (
              <ReactToPrint
                trigger={() => (
                  <Button key="print" type="primary">
                    {t("print")}
                  </Button>
                )}
                content={() => transactionSlipRef.current}
                key="1"
              />
            ),
          ]}
        />
      </Card>
      <Spin spinning={loading}>
        <Card>
          {data && (
            <Row gutter={[20, 20]}>
              {data.sale_invoice_status === SALES_INVOICE_STATUS_COMPLETED && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_transaction_id")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.uid ? data.uid : "-"}
                  </div>
                </Col>
              )}
              {data.sale_invoice_status !== SALES_INVOICE_STATUS_DRAFT && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("invoice_ID")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.invoice_number ? data.invoice_number : "-"}
                  </div>
                </Col>
              )}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("col_status")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.sale_invoice_status === SALES_INVOICE_STATUS_DRAFT && (
                    <Tag color="warning">{t("draft_invoices")}</Tag>
                  )}
                  {data.sale_invoice_status === SALES_INVOICE_STATUS_OPEN && (
                    <Tag color="warning">{t("open_invoices")}</Tag>
                  )}
                  {data.sale_invoice_status ===
                    SALES_INVOICE_STATUS_PARTIALLY && (
                    <Tag color="blue">{t("partially_paid_invoices")}</Tag>
                  )}
                  {data.sale_invoice_status ===
                    SALES_INVOICE_STATUS_COMPLETED && (
                    <Tag color="green">{t("paid_invoices")}</Tag>
                  )}
                </div>
              </Col>
              {authUser.type === USER_TYPE_BUSINESS_OWNER && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("branch")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.branch ? data.branch : "-"}
                  </div>
                </Col>
              )}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("col_created_at")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.created_at
                    ? moment(data?.created_at).format("MMM. D, YYYY hh:mm a")
                    : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("col_due_date")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.invoice_due_date
                    ? moment(data?.invoice_due_date).format("MMM. D, YYYY")
                    : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("customer_name")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.customer.name_ar ? data.customer.name_ar : "-"}
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  {t("col_amount")}
                </div>
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.amount_charged ? (
                    <CurrencyFormatter value={data.amount_charged} />
                  ) : (
                    "-"
                  )}
                </div>
              </Col>
              {data.sale_invoice_status !== SALES_INVOICE_STATUS_COMPLETED && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("col_balance")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {data.balance ? (
                      <CurrencyFormatter value={data.balance} />
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
              )}
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                  {data.items && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderBottom: "1px dashed #adadad",
                          padding: "10px 0",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {appConfig.direction === "ltr" ? "Items" : "الصنف"}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          <span style={{ width: "100px", textAlign: "center" }}>
                            {appConfig.direction === "ltr"
                              ? "Unit Price"
                              : "سعر الوحدة"}
                          </span>
                          <span
                            style={{
                              width: "250px",
                              textAlign:
                                appConfig.direction === "ltr"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            {appConfig.direction === "ltr"
                              ? "Price including tax"
                              : "السعر مع الضريبة"}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid #adadad",
                        }}
                      >
                        {data.items?.map((item) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingTop: "10px",
                            }}
                            key={"product_items_" + item.id}
                          >
                            <span>
                              {appConfig.direction === "ltr" ? (
                                <>
                                  {item.quantity} x <b>{item.name_en}</b>
                                </>
                              ) : (
                                <>
                                  {item.quantity} x <b>{item.name}</b>
                                </>
                              )}
                            </span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  width: "100px",
                                  textAlign: "center",
                                }}
                              >
                                <CurrencyFormatter
                                  value={item.price}
                                  currencyCode={false}
                                />
                              </span>
                              <span
                                style={{
                                  width: "250px",
                                  textAlign:
                                    appConfig.direction === "ltr"
                                      ? "right"
                                      : "left",
                                }}
                              >
                                <CurrencyFormatter
                                  value={item.subtotal}
                                  currencyCode={false}
                                />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingTop: "10px",
                        }}
                      >
                        {appConfig.direction === "ltr"
                          ? "Total without tax"
                          : "الإجمالي بدون الضريبة"}
                        <span>
                          <CurrencyFormatter
                            value={data.amount_charged - data.tax}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 0",
                          borderBottom: "1px solid #adadad",
                        }}
                      >
                        {appConfig.direction === "ltr"
                          ? "Total VAT"
                          : "مجموع ضریبة القیمة المضافة"}
                        <span>
                          <CurrencyFormatter value={data.tax} />
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 0 0",
                          fontWeight: "bold",
                        }}
                      >
                        {appConfig.direction === "ltr"
                          ? authUser.is_vat_exempt
                            ? "Total"
                            : "Total with tax"
                          : authUser.is_vat_exempt
                          ? "الإجمالي"
                          : "الإجمالي مع الضريبة"}

                        <b>
                          <CurrencyFormatter value={data.amount_charged} />
                        </b>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </Card>
        {paymentData.length > 0 && (
          <Card style={{ marginTop: "20px" }}>
            <Table
              columns={TableColumns}
              dataSource={paymentData}
              rowKey="id"
              pagination={false}
            />
          </Card>
        )}
      </Spin>
      <Modal
        open={modalVisible}
        title={t("register_payment")}
        onOk={updatePayment}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loadingBTN} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingBTN}
            onClick={updatePayment}
          >
            {t("confirm")}
          </Button>,
        ]}
      >
        <Form
          name="add-invoice-form"
          layout="vertical"
          onFinish={updatePayment}
          style={{ width: "100%", margin: "0" }}
          form={salesInvoiceForm}
        >
          <Form.Item
            name="payment"
            label={t("col_payment")}
            rules={[
              {
                required: true,
                message: t("validate_entity_required"),
              },
            ]}
            hasFeedback
          >
            <InputNumber
              min={0}
              max={data.balance}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            name="payment_method"
            label={t("select_payment_method")}
            rules={[
              {
                required: true,
                message: t("validate_entity_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              allowClear
              options={[
                {
                  value: "bank",
                  label: t("bank"),
                },
                {
                  value: "cash",
                  label: t("cash"),
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="balance" label={t("col_balance")}>
            <Input disabled={true} />
          </Form.Item>
          <Form.Item name="transaction_id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>

      {/* invoice printing code */}
      {data && (
        <div
          style={{
            padding: "10px 20px",
            position: "absolute",
            top: "0",
            zIndex: "-9999",
          }}
        >
          <div
            ref={transactionSlipRef}
            style={{
              padding: "20px",
              fontSize: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  width: "265px",
                }}
              >
                <img
                  id="store-logo"
                  style={{
                    maxHeight: "70px",
                    maxWidth: "200px",
                  }}
                  src={authUser.logo}
                  alt={authUser.business_name + "'s Logo"}
                />
              </div>
              <strong
                style={{
                  width: "265px",
                  textAlign: "center",
                }}
              >
                {data.reference_transaction ? (
                  <span>فاتورة الاسترجاع</span>
                ) : authUser.is_vat_exempt ? (
                  <span>فاتورة مبسطة</span>
                ) : (
                  <span>فاتورة ضريبية مبسطة</span>
                )}
                <div>{data.uid}</div>
              </strong>
              <div style={{ textAlign: "right", width: "265px" }}>
                <strong> {data.invoice_number} : رقم الفاتورة</strong>
                <div>
                  {moment(data.created_at).format("MM/DD/YYYY")} : تاريخ الاصدار
                </div>
                {data.reference_transaction ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      columnGap: "5px",
                    }}
                  >
                    <div>{data.reference_transaction?.uid}</div>
                    <div>: مرجع المعاملة</div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      columnGap: "5px",
                    }}
                  >
                    <div>{paymentData[0].payment_method.toUpperCase()}</div>
                    <div>: طريقة الدفع او السداد</div>
                  </div>
                )}
                {data.customer.name_ar && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      columnGap: "5px",
                    }}
                  >
                    <div>{data.customer.name_ar}</div>
                    <div>: اسم شركة المشتري</div>
                  </div>
                )}
                {data.customer.vat && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      columnGap: "5px",
                    }}
                  >
                    <div>{data.customer.vat}</div>
                    <div>: ضريبة القيمة المضافة للمشتري</div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  width: "200px",
                }}
              >
                <img
                  style={{
                    maxHeight: "70px",
                    maxWidth: "200px",
                  }}
                  src={transactionQRCode}
                  alt="QR Code"
                />
              </div>
              <div
                style={{
                  width: "200px",
                  textAlign: "center",
                }}
              >
                <div>
                  {authUser.vat_exempt === 1 ? "" : "VAT ID : " + authUser.vat}
                </div>
                <div>{authUser.cr ? "CR ID : " + authUser.cr : ""}</div>
              </div>
              <div style={{ textAlign: "right", width: "200px" }}>
                <strong> {authUser.business_name} </strong>
                <div>{authUser.address}</div>
                <div>{authUser.branch_name}</div>
                <div>{authUser.phone ? "+" + authUser.phone : ""}</div>
              </div>
            </div>
            {/* list area */}

            <div
              style={{ borderBottom: "1px solid #a8a8a8", paddingTop: "40px" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                paddingTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ textAlign: "center", width: "110px" }}>
                  <div>السعر مع الضريبة</div>
                  <div>Price including tax</div>
                </div>
                <div style={{ textAlign: "center", width: "100px" }}>
                  <div>سعر الوحدة</div>
                  <div>U.P. (net)</div>
                </div>
                <div style={{ textAlign: "center", width: "100px" }}>
                  <div>كمية</div>
                  <div>Qty</div>
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                <div>الوصف</div>
                <div>Description</div>
              </div>
            </div>
            <div
              style={{ borderBottom: "1px dashed #a8a8a8", paddingTop: "10px" }}
            />
            {data.items.map((item) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",

                  paddingTop: "10px",
                }}
                key={"product_items_" + item.id}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span style={{ textAlign: "center", width: "110px" }}>
                    <CurrencyFormatter
                      value={item.subtotal}
                      currencyCode={false}
                    />
                  </span>
                  <span style={{ textAlign: "center", width: "100px" }}>
                    <CurrencyFormatter
                      value={item.price}
                      currencyCode={false}
                    />
                  </span>
                  <span style={{ textAlign: "center", width: "100px" }}>
                    {item.quantity}
                  </span>
                </div>
                <span>
                  {item.name}
                  <br />
                  {item.name_en}
                </span>
              </div>
            ))}
            <div
              style={{ borderBottom: "1px solid #a8a8a8", paddingTop: "10px" }}
            />
            {/* Receipt total */}
            <div
              style={{
                maxWidth: "400px",
                border: "1px solid #a8a8a8",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 10px",
                }}
              >
                <span>
                  <CurrencyFormatter value={data.amount_charged - data.tax} />
                </span>
                <span>الإجمالي بدون الضريبة</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 10px",
                  borderBottom: "1px solid #a8a8a8",
                }}
              >
                <span>
                  <CurrencyFormatter value={data.tax} />
                </span>
                <span>مجموع ضریبة القیمة المضافة</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 10px",
                  fontWeight: "bold",
                }}
              >
                <span>
                  <CurrencyFormatter value={data.amount_charged} />
                </span>
                <span>
                  {authUser.is_vat_exempt ? "الإجمالي" : "الإجمالي مع الضريبة"}
                </span>
              </div>
            </div>
            {/* footer */}
            <div style={{ textAlign: "center", marginTop: "50px" }}>
              <small>Printed by https://anypos.app</small>
              <br />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewSalesInvoice;
